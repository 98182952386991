<template>
    <div class="zero">
        <div class="banner"></div>
        <div class="content">
            <a href="?r=/index/index" class="back"></a>
            <div class="total">
                <img src="https://sr.ffquan.cn/dtk_user_pmc/20220808/cboa71vt5kmqqboeesk00.png"/>
                <p>淘宝今日共<span>{{parseInt(totalNum+2)}}</span>个商品可捡漏</p>
            </div> 
            <p class="tips">羊毛礼金数量有限，领取后请尽快使用。如果礼金出现“已发完” <span>请收藏本网站（ctrl+D），每日10点，定时更新</span></p>    
            <div class="list-content">
                <div class="list-item">
                    <div class="img">
                        <span></span>
                        <img src="https://sr.ffquan.cn/dtk_user_pmc/20220808/cbobrckcs8fophq1ece00.png"/>
                    </div>
                    <h3 class="tit"><i></i>Apple iPhone苹果12全新【苹果13店内可选】5G全网通</h3>
                    <p class="pri">直接购买：¥ 4959</p>
                    <p class="old-pri">原价：¥5499</p>
                    <div class="button">
                        <span>限时免单</span>
                        <span class="num">￥<b>0</b></span>
                    </div>
                </div>
                <div class="list-item">
                    <div class="img">
                        <span></span>
                        <img src="https://sr.ffquan.cn/dtk_user_pmc/20220808/cbobqpccs8fophq1eccg0.png"/>
                    </div>
                    <h3 class="tit"><i></i>戴森(Dyson) 新一代吹风机电吹风 负离子 进口家用 礼.</h3>
                    <p class="pri">直接购买：￥3299</p>
                    <p class="old-pri">原价：￥3499</p>
                    <div class="button">
                        <span>限时免单</span>
                        <span class="num">￥<b>0</b></span>
                    </div>
                </div>
                <div class="list-item" style="cursor:pointer" :id="'goodsItem_'+item.id" @click="jumpDetaile(item,index)" v-for="(item,index) in goodThingList" :key="item.id">
                    <a class="img">
                        <span v-if="item.rights_id && !item.cashFiftStuts" ></span>
                        <img :src="item.mainPic + '_310x310.jpg'" />
                    </a>
                    <h3 class="tit"><i></i>{{ item.dtitle || item.title }}</h3>
                    <p class="pri">直接购买：￥{{ item.actualPrice }}</p>
                    <p class="old-pri">原价：￥{{ item.originalPrice }}</p>
                    <div class="button">
                        <span>限时免单</span>
                        <span class="num">￥<b>{{
                        item.rights_id
                        ? parseFloat(item.actualPrice - item.per_face).toFixed(2)/1 &lt; 0
                        ? 0
                        : parseFloat(item.actualPrice - item.per_face).toFixed(2)/1 
                        : item.actualPrice
                        }}</b></span>
                    </div>
                </div>
            </div> 
        </div> 
    </div>
    
</template>

<script>
import { importCDN } from "@/utils/utils";
import { getOperateColumn,getSearchGoods} from "@/services";
import Loading from "@/components/loading";
import { mapGetters } from "vuex";

export default {
    data () {
        return {
            goodThingList:[],
            totalNum:0,
        }
    },
    computed: {
        ...mapGetters(["marketGroup", "config"]),
    },
    methods: {
        async getOperateColumnInfo() {
            const { data } = await getOperateColumn({ id: 6,siteId:this.config.site_id});
            let gids = Object.keys(data);
            if (!gids.length) return;
            const goodsRes = await getSearchGoods({
                ids: gids.join(","),
            });

            goodsRes.data.list.map((item) => {
                for (const key in data) {
                    const element = data[key];
                    if (item.id == Number(key)) {
                        item.cashFiftStuts = element.status;
                        item.rights_id = element.rights_id;
                        item.tlj_short_url = element.tlj_short_url;
                        item.per_face = element.per_face;
                        item.sort = element.sort;
                    }
                }
            });
            goodsRes.data.list.sort((a, b) => a.sort - b.sort);
            this.goodThingList = goodsRes.data.list;
            this.totalNum = goodsRes.data.totalNum
        },
        jumpDetaile(item,index) {
            try {
                this.$BIJsdk.onEvent({
                    event:document.getElementById('goodsItem_'+item.id),
                    extra:{
                        gid:item.id,
                        index:parseInt(index)+2
                    },
                    event_type:'click',
                })
            } catch (error) {}

            if (item.rights_id && item.cashFiftStuts && item.tlj_short_url) {
                return window.open(item.tlj_short_url, "_blank");
            } else if (item.rights_id && !item.cashFiftStuts) {
                return;
            }
            window.open(`index.php?r=l/d&id=${item.id}&u=1`, "_blank");
        },
    },
    mounted () {
        this.getOperateColumnInfo()
    },
}
</script>

<style scoped lang="less">
.w_1200{
    max-width: 1200px;
    margin: 0 auto;
}
.zero{
    padding-bottom: 50px;
    .banner{
        width:100%;
        height:260px;
        background:url(https://sr.ffquan.cn/dtk_user_pmc/20220808/cboa6ur31mkb3tm2cpf00.png) no-repeat center / cover;
    }
    .content{
        width:1200px;
        margin:0 auto;
        position:relative;
        .back{
            position: absolute;
            width: 42px;
            height: 79px;
            left: -70px;
            top: 20px;
            background: url(https://sr.ffquan.cn/dtk_user_pmc/20220808/cbobre4cs8fophq1ecf00.png) no-repeat center / contain;
        }
    }
    .total{
        display:flex;
        align-items: center;
        margin: 25px 0 10px;
        img{
            width:29px;
        }
        p{
            font-weight: Bold;
            font-size: 16px;
            color: #333333;
            margin-left: 10px;
            display:flex;
            align-items: center;
            span{
                width: 25px;
                height: 19px;
                background: #474747;
                border-radius: 4px;
                font-size: 15px;
                color: #FFFFFF;
                display:flex;
                justify-content: center;
                margin: 0 5px;
            }
        }
    }
    .tips{
        width: 1200px;
        height: 40px;
        background: #FFEBB8;
        border-radius: 5px;
        font-size: 14px;
        color: #E56300;
        display:flex;
        align-items: center;
        padding-left: 30px;
        box-sizing: border-box;
        margin-bottom: 15px;
        span{
            color:#E50400;
            margin-left: 20px;
        }
    }
    .list-content{
        display:flex;
        flex-wrap: wrap;
        .list-item{
            width: 230px;
            height: 360px;
            background: #FFFFFF;
            padding:15px;
            box-sizing: border-box;
            margin-bottom: 12px;
            position:relative;
            margin-right: 12px;
            &:nth-child(5n){
                margin-right:0;
            }
            .img{
                position: relative;
                width: 200px;
                height: 200px;
                display: block;
                span{
                    position:absolute;
                    width: 100px;
                    height: 100px;
                    left:50%;
                    top:50%;
                    margin:-50px 0 0 -50px;
                    background: url(https://sr.ffquan.cn/dtk_user_pmc/20220808/cbobda4cs8fophq1e9jg0.png) no-repeat center / contain;
                }
                img{
                    width:200px;height:200px;
                }
            }
            .tit{
                font-size: 14px;
                color: #333333;
                font-weight: normal;
                margin: 10px 0 5px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                i{
                    width: 14px;height: 14px;
                    float:left;
                    position: relative;
                    top: 3px;
                    margin-right: 3px;
                    background: url(https://sr.ffquan.cn/dtk_user_pmc/20220808/cbobqn7t5kmqqboef5s00.png) no-repeat center / contain;
                }
            }
            .pri{
                font-weight: 500;
                font-size: 18px;
                color: #FF661B;
                margin: 8px 0 8px;
            }
            .old-pri{
                font-size: 16px;
                color: #ABABAB;
                border-top: 1px solid #ABABAB;
                display: inline-block;
                line-height: 0;
                margin-top: 10px;
            }
            .button{
                position: absolute;
                bottom: 0;
                background: url(https://sr.ffquan.cn/dtk_user_pmc/20220808/cboa70j31mkb3tm2cpg00.png) no-repeat center / contain;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                left: 0;
                font-size: 16px;
                color: #FFFFFF;
                padding: 0 15px;
                box-sizing: border-box;
                .num{
                    b{
                        font-size: 40px;
                        font-weight: normal;
                    }
                }
            }
        }
    }
}
</style>