var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "zero" }, [
    _c("div", { staticClass: "banner" }),
    _c("div", { staticClass: "content" }, [
      _c("a", { staticClass: "back", attrs: { href: "?r=/index/index" } }),
      _c("div", { staticClass: "total" }, [
        _c("img", {
          attrs: {
            src:
              "https://sr.ffquan.cn/dtk_user_pmc/20220808/cboa71vt5kmqqboeesk00.png"
          }
        }),
        _c("p", [
          _vm._v("淘宝今日共"),
          _c("span", [_vm._v(_vm._s(parseInt(_vm.totalNum + 2)))]),
          _vm._v("个商品可捡漏")
        ])
      ]),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "list-content" },
        [
          _vm._m(1),
          _vm._m(2),
          _vm._l(_vm.goodThingList, function(item, index) {
            return _c(
              "div",
              {
                key: item.id,
                staticClass: "list-item",
                staticStyle: { cursor: "pointer" },
                attrs: { id: "goodsItem_" + item.id },
                on: {
                  click: function($event) {
                    return _vm.jumpDetaile(item, index)
                  }
                }
              },
              [
                _c("a", { staticClass: "img" }, [
                  item.rights_id && !item.cashFiftStuts ? _c("span") : _vm._e(),
                  _c("img", { attrs: { src: item.mainPic + "_310x310.jpg" } })
                ]),
                _c("h3", { staticClass: "tit" }, [
                  _c("i"),
                  _vm._v(_vm._s(item.dtitle || item.title))
                ]),
                _c("p", { staticClass: "pri" }, [
                  _vm._v("直接购买：￥" + _vm._s(item.actualPrice))
                ]),
                _c("p", { staticClass: "old-pri" }, [
                  _vm._v("原价：￥" + _vm._s(item.originalPrice))
                ]),
                _c("div", { staticClass: "button" }, [
                  _c("span", [_vm._v("限时免单")]),
                  _c("span", { staticClass: "num" }, [
                    _vm._v("￥"),
                    _c("b", [
                      _vm._v(
                        _vm._s(
                          item.rights_id
                            ? parseFloat(
                                item.actualPrice - item.per_face
                              ).toFixed(2) /
                                1 <
                              0
                              ? 0
                              : parseFloat(
                                  item.actualPrice - item.per_face
                                ).toFixed(2) / 1
                            : item.actualPrice
                        )
                      )
                    ])
                  ])
                ])
              ]
            )
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "tips" }, [
      _vm._v("羊毛礼金数量有限，领取后请尽快使用。如果礼金出现“已发完” "),
      _c("span", [_vm._v("请收藏本网站（ctrl+D），每日10点，定时更新")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list-item" }, [
      _c("div", { staticClass: "img" }, [
        _c("span"),
        _c("img", {
          attrs: {
            src:
              "https://sr.ffquan.cn/dtk_user_pmc/20220808/cbobrckcs8fophq1ece00.png"
          }
        })
      ]),
      _c("h3", { staticClass: "tit" }, [
        _c("i"),
        _vm._v("Apple iPhone苹果12全新【苹果13店内可选】5G全网通")
      ]),
      _c("p", { staticClass: "pri" }, [_vm._v("直接购买：¥ 4959")]),
      _c("p", { staticClass: "old-pri" }, [_vm._v("原价：¥5499")]),
      _c("div", { staticClass: "button" }, [
        _c("span", [_vm._v("限时免单")]),
        _c("span", { staticClass: "num" }, [
          _vm._v("￥"),
          _c("b", [_vm._v("0")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list-item" }, [
      _c("div", { staticClass: "img" }, [
        _c("span"),
        _c("img", {
          attrs: {
            src:
              "https://sr.ffquan.cn/dtk_user_pmc/20220808/cbobqpccs8fophq1eccg0.png"
          }
        })
      ]),
      _c("h3", { staticClass: "tit" }, [
        _c("i"),
        _vm._v("戴森(Dyson) 新一代吹风机电吹风 负离子 进口家用 礼.")
      ]),
      _c("p", { staticClass: "pri" }, [_vm._v("直接购买：￥3299")]),
      _c("p", { staticClass: "old-pri" }, [_vm._v("原价：￥3499")]),
      _c("div", { staticClass: "button" }, [
        _c("span", [_vm._v("限时免单")]),
        _c("span", { staticClass: "num" }, [
          _vm._v("￥"),
          _c("b", [_vm._v("0")])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }